import "./index.scss"
import { Link } from "react-router-dom"
import InstaFeed from "../InstaFeed/index"

const Home = () => {
  return (
    <>
      <div className="container container-home">
        <div className="home-about-us txt-justify ">
          <div>
            <h3>
              <strong>About Us</strong>
            </h3>
            <p>
              In4Squash Ireland is a fully inclusive squash group for LGBTQ+
              squash players and their supporters based in Dublin City Centre.
              The group has been active for over 10 years and caters for players
              at all levels, from complete beginners to strong club players. The
              mission for the club is to promote inclusion and diversity in
              squash. Meet the organising{" "}
              <Link
                to="/committee"
                relative="path"
              >
                <strong>committee</strong>
              </Link>
              .
            </p>
            <p>
              We are a friendly group who love squash and also enjoy meeting
              people from all backgrounds and nationalities. In4Squash is an
              affiliated club with Irish Squash, the NGB for Squash on the
              island of Ireland, with most of our active members also members of
              Irish Squash. The club meet 2 or 3 times a week for social squash{" "}
              <Link
                to="/sessions"
                relative="path"
              >
                <strong>sessions</strong>
              </Link>{" "}
              with our coach, and also organise regular social events throughout
              the year.
            </p>
            <p>
              Our annual International LGBTQ+ In4Squash{" "}
              <Link
                to="/tournaments"
                relative="path"
              >
                <strong>tournament</strong>
              </Link>{" "}
              is held every November and is one of the highlights of the year,
              attracting many players from other LGBTQ+ squash groups across
              Europe. The National All Ireland Pride Squash Tournament is held
              every May/June and is an all-inclusive national tournament for all
              LGBTQ+ players and their supporters from all regions in Ireland.
              Many of our members also participate in other European squash
              tournaments that are held across various European cities
              throughout the year, so there are opportunities for some great
              weekend breaks away.
            </p>
            <p>
              If you are new to squash you can come along to one of our newbie
              nights on the first Monday of every month, where our training
              officers with show you the basics of the game. So whatever your
              level of squash, come and try it out for fun or serious
              competition. Looking forward to developing inclusive and diverse
              friendships on and off the courts!
            </p>
          </div>
        </div>
        <div className="home-instagram">
          <InstaFeed />
        </div>
      </div>
    </>
  )
}

export default Home
