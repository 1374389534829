import "./index.scss"

const Tournaments = () => {
  return (
    <>
      <div className="container container-tournaments">
        <div className="txt-justify">
          <h1>Tournaments</h1>
          <p>
            In4Squash love friendly competition and throughout the year there
            are number of both National and International tournaments organised
            for competition and fun. Our annual International LGBTQ+ In4Squash
            tournament is held every November and is one of the highlights of
            the year, attracting many players from other LGBTQ+ squash groups
            across Europe and beyond.
          </p>
          <p>
            Our National Tournament is held every year at the start of summer
            and from 2024, in association with Irish Squash and Ulster Squash
            the tournament will be rebranded the "National All Ireland Pride
            Squash Tournament". This annual event will be an all-inclusive
            national tournament for all LGBTQ+ players and their supporters from
            all regions in Ireland. Many of the In4Squash members also
            participate in other European squash tournaments that are held
            across various European cities throughout the year, so there are
            opportunities for some great weekend breaks away. Follow this link
            for a list of{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.grcdi.nl/gaysquash/tournaments.htm"
            >
              <strong>annual conferences</strong>
            </a>
            .
          </p>
          <p>
            We also arrange a teams tournament in December each year, where
            players from all levels from intermediates to complete beginners
            group together to compete for the In4Squash Xmas Cup.
          </p>
        </div>
      </div>
    </>
  )
}

export default Tournaments
