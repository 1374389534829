import "./index.scss"
import { useState } from "react"
import { NavLink } from "react-router-dom"

const Menu = () => {
  const [showNav, setShowNav] = useState(false)
  return (
    <div className="container-menu">
      <nav>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}
        >
          About
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="sessions-link"
          to="/sessions"
          onClick={() => setShowNav(false)}
        >
          Sessions
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="tournaments-link"
          to="/tournaments"
          onClick={() => setShowNav(false)}
        >
          Tournaments
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setShowNav(false)}
        >
          Contact
        </NavLink>
      </nav>
    </div>
  )
}

export default Menu
