import "./index.scss"

const InstaGrid = ({ items }) => {
  return (
    <>
      <div className="gridStyle">
        {items.map((item, index) => (
          <img
            key={index}
            src={item.mediaUrl}
            className="itemStyle"
          />
        ))}
      </div>
    </>
  )
}

export default InstaGrid
