import "./index.scss"
import { faMeetup } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const Sessions = () => {
  return (
    <>
      <div className="container container-sessions">
        <div className="txt-justify">
          <h1>Sessions</h1>
          <p>
            All of our sessions are organised through the MeetUp Platform. For
            an up to date list of the upcomming session follow the MeetUp link
            here.{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.meetup.com/dublin-in4squash-meetup-group/"
              className="meetup-size"
            >
              <FontAwesomeIcon
                icon={faMeetup}
                color="#ED1C40"
              />
            </a>
          </p>
          <p>
            The club meet 2 or 3 times a week for social squash sessions in
            Trinity College Dublin and Sportslink Santry, and also organise
            regular social events throughout the year. The social squash
            sessions attract a range of players from the greater Dublin area and
            beyond, and aims to help everyone improve their game with access to
            our coaches every Thursday.
          </p>
          <p>
            If you are new to squash you can come along to one of our newbie
            nights on the first Monday of every month, where our training
            officers with show you the basics of the game, provide spare
            racquets and join you for a social drink after to meet new friends.
            There is a section on the website for Squash{" "}
            <Link
              to="/rulesandsafety"
              relative="path"
            >
              <strong>rules and safety</strong>
            </Link>{" "}
            and also some tips (so please review this section before coming to
            the newbies session). There is a list of upcoming training and
            social squash sessions in the events section, and these are run
            through the app Meetup.com. We also organise periodic training camps
            and blocks of lessons with our professional Irish Squash coaches.
          </p>
          <p>
            On Mondays we focus on mini games and drills, with a mini-teams
            event, and Thursdays we play box games so there is a group for every
            player at every level. We also have an In4Squash{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.sportyhq.com/ladder/view/794"
            >
              <strong>ladder</strong>
            </a>{" "}
            for more competitive players.
          </p>
        </div>
      </div>
    </>
  )
}

export default Sessions
