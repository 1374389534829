import "./index.scss"

const Support = () => {
  return (
    <>
      <div className="container container-support">
        <div>
          <h1>Support</h1>
          <p>
            Our Spring Teams Tournament is a team based tournament and is only
            open to OutPlay members. This consists of teams of 4 players of
            mixed abilities competing against each other in a round-robin format
            and is one of our most popular events each year.
          </p>
        </div>
      </div>
    </>
  )
}

export default Support
