import "./index.scss"
import LogoOne from "./../../assets/images/committee-graham.jpeg"
import LogoTwo from "./../../assets/images/committee-lorcan.jpeg"
import LogoThree from "./../../assets/images/committee-ronan.jpeg"
import LogoFour from "./../../assets/images/committee-yohander.jpeg"
import LogoFive from "./../../assets/images/committee-brett.jpeg"
import LogoSix from "./../../assets/images/committee-dan.png"
import LogoSeven from "./../../assets/images/committee-gav.png"

const Committee = () => {
  return (
    <>
      <div className="container container-committee">
        <div className="txt-justify ">
          <h1>Committee</h1>
          <p>
            The In4Squash committee runs the club on behalf of all of the
            members and is selected at the AGM in January. It comprises 7
            members, three exec committee members (President, Secretary and
            Treasurer who are elected for 3 year term) and 4 other members that
            are elected annualy.
          </p>
          <p>
            The Committee are responsible for all the administrative functions
            of the club from organising sessions, training and club tournaments,
            to social outings and outreach.
          </p>
          <p>
            If you would like to get involved or help out, feel free to let us
            know in4squash@gmail.com.
          </p>
        </div>
        <ul>
          <li>
            <img
              src={LogoOne}
              alt="Role"
            />
            <div>
              <p>
                President - <strong>Graham Pidgeon</strong>
              </p>
              <p>
                Responsibilities: Oversees the In4Squash committee and organises
                the International tournament and coaching.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoTwo}
              alt="Role"
            />
            <div>
              <p>
                Secretary - <strong>Lorcan Walsh</strong>
              </p>
              <p>
                Responsibilities: Takes minutes at all meetings, controls
                membership and organises the weekly squash sessions.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoThree}
              alt="Role"
            />
            <div>
              <p>
                Treasurer - <strong>Ronan MacMuiris</strong>
              </p>
              <p>
                Responsibilities: Has oversight of the finances and informs the
                committee of cash flow.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoFour}
              alt="Role"
            />
            <div>
              <p>
                Social Outreach and Diversity Officer -
                <strong>Yohander Gomez Pidgeon</strong>
              </p>
              <p>
                Responsibilities: Controls the social media platforms and
                communications including Instagram and facebook to promote
                diversity in the club.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoFive}
              alt="Role"
            />
            <div>
              <p>
                Social Officer - <strong>Brett Stout</strong>
              </p>
              <p>
                Responsibilities: Organises all social activities and events
                unrelated to squash.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoSix}
              alt="Role"
            />
            <div>
              <p>
                Tournament Support Officer - <strong>Daniel McCallig</strong>
              </p>
              <p>
                Organises the National Squash tournament and provides support
                for the international tournaments.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
          <li>
            <img
              src={LogoSeven}
              alt="Role"
            />
            <div>
              <p>
                Players Rep and Training Officer -{" "}
                <strong>Gavin O'Reilly</strong>
              </p>
              <p>
                Responsibilities: Runs the monthly newbie sessions and links
                with our female liaison officer to provide basic training on
                squash rules to encourage new player development.
              </p>
              <p>This is an elected role</p>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Committee
