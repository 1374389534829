import "./index.scss"
import Logo from "./../../assets/images/header.png"
import Menu from "./../Menu"

const Header = () => {
  return (
    <>
      <div className="header">
        <img
          src={Logo}
          alt="In4squash Ireland"
        />
        <Menu />
      </div>
    </>
  )
}

export default Header
