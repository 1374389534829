import { useState } from "react"
import InstaGrid from "../InstaGrid"
import { useEffect } from "react"

const InstaFeed = () => {
  const [instaItems, setInstaItems] = useState([])
  const userId = "6574578525926637"
  const accessToken =
    "IGQVJVMHJnUktrTUdBMnRRdUJlUHdiS3lpOVVtYzU0eVN3VVZAiS1hhRjcxODAzLVFiby1TMkNwRE9wWjlVOWNDSlQ1ekZAIZAXBfLVMtVXZApWEZAnYS1lXzhkY0tPLVpxd2RZAWW9UZA2JQekc3clh5blppSgZDZD"

  const instaUrl = `https://graph.instagram.com/${userId}/media?access_token=${accessToken}`

  useEffect(() => {
    const fetchMedia = async (id) => {
      const mediaUrl = `https://graph.instagram.com/${id}?access_token=${accessToken}&fields=media_url,permalink`

      const res = await fetch(mediaUrl)
      const json = await res.json()

      const instaItem = {
        permalink: json.permalink,
        mediaUrl: json.media_url,
      }

      return instaItem
    }

    const doFetch = async () => {
      if (!userId || !accessToken) {
        console.log("userId or accessToken is undefined ", {
          userId,
          accessToken,
        })
        return
      }

      const res = await fetch(instaUrl)
      const json = (await res.json()).data

      console.log(json)

      let fetchedItems = []

      if (json) {
        for (let i = 0; i < json.length && i < 9; i++) {
          const item = json[i]
          const itemId = item.id

          const instaItem = await fetchMedia(itemId)
          fetchedItems.push(instaItem)
        }
      }

      setInstaItems(fetchedItems)
    }

    doFetch()
  }, [userId, accessToken, instaUrl])

  return (
    <>
      {instaItems.length > 0 && (
        <>
          <h3>
            <strong>News</strong>
          </h3>
          <InstaGrid items={instaItems} />
        </>
      )}
    </>
  )
}

export default InstaFeed
