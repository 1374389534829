import "./index.scss"
import { Link } from "react-router-dom"

const Rules = () => {
  return (
    <>
      <div className="container container-contact">
        <div className="txt-justify">
          <h1>Rules and Safety</h1>
          <p>
            A list of the basic rules for squash is available via this link:
          </p>
          <p>
            Getting Started:{" "}
            <Link
              to="/files/Getting-Started.pdf"
              target="_blank"
              download
            >
              Download
            </Link>
          </p>
          <p>A more detailed list of the rules of squash is available here:</p>
          <p>
            Rules of Squash:{" "}
            <a
              href=" https://www.irishsquash.com/wp-content/uploads/2023/01/Rules-of-Singles-Squash-2020-V2.pdf"
              download
            >
              Download
            </a>
          </p>
          <p>
            The rules of squash can be confusing for players new to the sport,
            with "lets" and "strokes" awarded to players in certain
            circumstances. At In4Squash we always err on the side of caution and
            players safety is paramount.{" "}
          </p>
          <p>
            If you think there is even the slightest chance of hitting your
            opponent with the ball, your racket, or your body, make every
            possible effort to stop play and ask for a "let". Tell your opponent
            why you stopped and then replay the rally.
          </p>
          <p>
            If you have an injury, think before you play as you may aggravate
            the injury. Ensure that you stretch properly before playing, this is
            called the "warm up" and also stretch after your game, the "cool
            down". Approved eye guards are strongly recommended.
          </p>
        </div>
      </div>
    </>
  )
}

export default Rules
