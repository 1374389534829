import "./App.scss"
import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import Home from "./components/Home"
import Committee from "./components/Committee"
import Sessions from "./components/Sessions"
import Tournaments from "./components/Tournaments"
import Support from "./components/Support"
import Contact from "./components/Contact"
import Rules from "./components/Rules"

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Layout />}
        >
          <Route
            index
            element={<Home />}
          />
          <Route
            path="committee"
            element={<Committee />}
          />
          <Route
            path="sessions"
            element={<Sessions />}
          />
          <Route
            path="tournaments"
            element={<Tournaments />}
          />
          <Route
            path="support"
            element={<Support />}
          />
          <Route
            path="contact"
            element={<Contact />}
          />
          <Route
            path="rulesandsafety"
            element={<Rules />}
          />
        </Route>
      </Routes>
    </>
  )
}

export default App
