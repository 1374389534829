import "./index.scss"
import {
  faFacebook,
  faInstagram,
  faMeetup,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Logo from "../../assets/images/bottom.png"
import LogoIn from "./../../assets/images/logo.png"

const Footer = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer">
      <div className="footer-logo">
        <img
          src={LogoIn}
          alt="In4squash Ireland"
        />
      </div>
      <div className="footer-content">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/groups/in4squashireland"
        >
          <FontAwesomeIcon
            icon={faFacebook}
            color="#4267B2"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/in4squash/"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            color="#F56040"
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.meetup.com/dublin-in4squash-meetup-group/"
        >
          <FontAwesomeIcon
            icon={faMeetup}
            color="#ED1C40"
          />
        </a>
      </div>
      <div>
        <p>© {currentYear} In4squash Ireland. All rights reserved.</p>
      </div>
      <div>
        <p>Design by Igor Carneiro</p>
      </div>
      <div>
        <img
          src={Logo}
          alt="In4squash Ireland"
        />
      </div>
    </div>
  )
}

export default Footer
