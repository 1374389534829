import "./index.scss"
import { useRef } from "react"
import {
  faFacebook,
  faInstagram,
  faMeetup,
} from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    // e.preventDefault()
    // emailjs
    //   .sendForm('gmail', 'template_YeJhZkgb', form.current, 'your-token')
    //   .then(
    //     () => {
    //       alert('Message successfully sent!')
    //       window.location.reload(false)
    //     },
    //     () => {
    //       alert('Failed to send the message, please try again')
    //     }
    //   )
  }

  return (
    <>
      <div className="container container-contact">
        <div>
          <h1>Contact Us</h1>
          <p>
            Whether you are just starting out in squash or have been at it for
            years, we would love to hear from you.
          </p>
          <p>
            If you would like to be kept updated with information about
            sessions, tournaments, social events, and any other news please
            check our Facebook Group (link below), or ask to be added to our
            WhatsApp News Group.
          </p>
        </div>
        <div className="contact-media-social">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/groups/in4squashireland"
          >
            <FontAwesomeIcon
              icon={faFacebook}
              color="#4267B2"
            />{" "}
            Find us on Facebook
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/in4squash/"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              color="#F56040"
            />{" "}
            Find us on Instagram
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.meetup.com/dublin-in4squash-meetup-group/"
          >
            <FontAwesomeIcon
              icon={faMeetup}
              color="#ED1C40"
            />{" "}
            Find us on MeetUp
          </a>
        </div>
        {/* <div className="contact-form">
          <p>
            Whether you are just starting out in squash or have been at it for
            years, we would love to hear from you.
          </p>
          <form
            ref={form}
            onSubmit={sendEmail}
          >
            <ul>
              <li className="half">
                <input
                  placeholder="Name"
                  type="text"
                  name="name"
                  required
                />
              </li>
              <li className="half">
                <input
                  placeholder="Email"
                  type="email"
                  name="email"
                  required
                />
              </li>
              <li>
                <input
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  required
                />
              </li>
              <li>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </li>
              <li>
                <input
                  type="submit"
                  className="flat-button"
                  value="SEND"
                />
              </li>
            </ul>
          </form>
        </div> */}
      </div>
    </>
  )
}

export default Contact
